import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavItem, Nav } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import  CSignOut from "../../Auth/CSignOut";

export default function Navigation(){
  return(
    <div className="navbar-container">
     <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/home">Campaign Manager</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/Campaigns">
              <NavItem className="nav-link">View Campaigns</NavItem>
            </LinkContainer>
            <LinkContainer to="/Triggers">
              <NavItem className="nav-link">Triggers</NavItem>
            </LinkContainer>
            <LinkContainer to="/Templates">
              <NavItem className="nav-link">Template</NavItem>
            </LinkContainer>
            <LinkContainer to="/Chat">
              <NavItem className="nav-link">Chat</NavItem>
            </LinkContainer>
            <LinkContainer to="/DesignStudio">
              <NavItem className="nav-link">Design Studio</NavItem>
            </LinkContainer>
            <LinkContainer to="/WhatsApp">
              <NavItem className="nav-link">WhatsApp</NavItem>
            </LinkContainer>
            <LinkContainer to="/WhatsAppChat">
              <NavItem className="nav-link">WhatsApp Conversations</NavItem>
            </LinkContainer>
            <LinkContainer to="/cdn">
              <NavItem className="nav-link">CDN</NavItem>
            </LinkContainer>
            {/* <LinkContainer to="/analytics">
              <NavItem className="nav-link">Analytics</NavItem>
            </LinkContainer> */}
          </Nav>
          {/* <Nav>
            <Nav.Link href="#"></Nav.Link>
            <Nav.Link eventKey={2} href="#">
            </Nav.Link> 
          </Nav> */}
        </Navbar.Collapse>
        <CSignOut/>
      </Navbar>
    </div>
  )
}

Nav.propTypes = {
  msg: PropTypes.string
}
