import React, { useEffect, useRef, useState } from 'react';
import { post, get } from 'axios';
import EmailEditor from 'react-email-editor';

import { Auth } from 'aws-amplify';

import { encode as base64_encode} from 'base-64';
import { getCampaignData } from '../../services';

export async function getJwtToken (e) {
  const currentSession = await Auth.currentSession();
  const providerId = currentSession.getIdToken().payload.sub;
  const identityJwt = currentSession.getIdToken().getJwtToken();
  var p2 = new Object();
  p2.token = identityJwt;
  p2.files = e;
  return p2;
}

const Cdn = (props) => {
  const emailEditorRef = useRef(false);
  const [emailEditor, setEmailEditor] = useState();
  const [contentMode, setContentMode] = useState();
  const [htmlText, setHtmlText] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [htmlDesignText, setHtmlDesignText] = useState();
  const [zipUploadPaths, setZipUploadPaths] = useState([]);
  const [zipUploadInProgress, setZipUploadInProgress] = useState(false);
  const [showZipUploadSelection, setShowZipUploadSelection] = useState(false);
  
  


  useEffect(() => {   
    if (props && props.match && props.match.params && props.match.params.id) {
      let campaignId = parseInt(props.match.params.id); 
      //alert("campaignId"+campaignId);
      getCampaignData({})
      .then( response =>{
          //console.log("response getCampaignData", response.data);
          const campaigns = response.data.campaigns;
          if (campaigns && campaigns.length > 0) {
            let htmlText = null;
            var i=0;
            for (i=0;i<campaigns.length;i++) {
              const id = campaigns[i]['id'];
              if (id === campaignId) {
                //alert("match found");
                htmlText = campaigns[i]['s3_object'];
                break;
              }
            }
            if (htmlText) {
              setContentMode("html_editor");
              emailEditorRef.current.editor.loadDesign({html: htmlText, classic: true});
            }
          }
      })
      .catch(error => {
        console.log("campaign data load error", error);
      })
    }
    
    //alert("opening desing studio"); 
      setEmailEditor(<EmailEditor ref={emailEditorRef} 
        onLoad={onLoad} onReady={onReady} id="emailEditor" minHeight="100vh"/>);
    
  }, []);

  const updateHtmlText = (e) => {
    const html = e.target.value;
    setHtmlText(html);
  }
  
  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
      const element = document.createElement("a");
      var fileData = JSON.stringify(design, undefined, 4); // first use JSON.stringify 
      var file = new Blob([fileData], {type: "text/json;charset=utf-8"}); // save as Blob 
      element.href = URL.createObjectURL(file);
      element.download = "design.json";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      element.remove();
    });
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      const element = document.createElement("a");
      var file = new Blob([html], {type: "text/html;charset=utf-8"}); // save as Blob 
      element.href = URL.createObjectURL(file);
      element.download = "index.html";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      element.remove();
    });
  };

  const getEditorHtmlText = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setHtmlDesignText(html);
    });
  };

  const exportHtmlText = () => {
      const html = htmlText;
      const element = document.createElement("a");
      var file = new Blob([html], {type: "text/html;charset=utf-8"}); // save as Blob 
      element.href = URL.createObjectURL(file);
      element.download = "index.html";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      element.remove();
  };

  const toggleShowPreview = () => {
    let showPreviewFlag = !showPreview;
    setShowPreview(showPreviewFlag);
    if (showPreviewFlag && (contentMode === 'html_editor' || contentMode === 'html_zip_editor_processed')) {
      getEditorHtmlText();
    }
  };


  const onDesignLoad = (data) => {
    console.log('onDesignLoad');
    //console.log('onDesignLoad', data);
  };

  const onLoad = () => {
    console.log('onLoad');

    emailEditorRef.current.editor.addEventListener(
      'design:loaded',
      onDesignLoad
    );
    console.log("content mode", contentMode);

      //image upload integration
      emailEditorRef.current.editor.registerCallback('image', function(f, done) {
        const file = f.attachments[0];
        // Do something to upload the image and return the URL of the uploaded image
        console.log("image upload started");

        post('https://api.eiskot.com/uploadfile?update=NOTHING&filename='+ file.name).then((response)=>{
            //console.log(response.data);
            const apiResponse = response.data.path;
            const id_key = response.data.id;
            var formData = new FormData();
            formData = {
              ...apiResponse.fields,
              file: file
            }
            const config = {
              headers: {
                  'content-type': 'multipart/form-data',
                  'x-amz-acl': 'public-read'
              }
            }
            post(apiResponse.url, formData, config).then((response)=>{
              //console.log(response.data);      
              //mark it as public
              post('https://api.eiskot.com/uploadfile?update='+id_key).then((response)=>{
                console.log("public url details "+ response.data)
                done({url:response.data.url});
              });  
            });
          });      
        });
  }

  const onReady = () => {
    console.log('onReady');    
    //remove duplicate iframe from content editor (first one is removed)
    if (document.getElementsByTagName("iframe") && document.getElementsByTagName("iframe").length > 1) {
      document.getElementsByTagName("iframe")[0].setAttribute("style","display:none");
    }    
  };

  const importHtml = async (e, studioOrTextMode) => {
    e.preventDefault();  
    const file = e.target.files[0];
    let contentModeValue = null;
    if ( file.name.endsWith(".zip")) {
      if (studioOrTextMode === 'studio') {
        contentModeValue = 'html_zip_editor';
      } else {
        contentModeValue = 'html_zip_text';
      }      
    } else {
      if (studioOrTextMode === 'studio') {
        contentModeValue = 'html_editor';
      } else {
        contentModeValue = 'html_text';
      }      
    }
     
    setContentMode(contentModeValue);
    console.log(contentModeValue);
    
    if (contentModeValue === 'html_editor' || contentModeValue === 'html_text') {
      const reader = new FileReader();
      reader.onload = async (e) => { 
        const text = (e.target.result);
        console.log(contentModeValue);
        if (contentModeValue === 'html_editor') {
          emailEditorRef.current.editor.loadDesign({html: text, classic: true});
        } else if (contentModeValue === 'html_text'){
          console.log("show text in text editor");
          setHtmlText(text);        
        }
      };
      reader.readAsText(file);
    }

    if (contentModeValue === 'html_zip_editor' || contentModeValue === 'html_zip_text') {    
        setZipUploadInProgress(true);
        submitCustomParams().then((response)=> {
          const responseId = response.data.id;
          zipFileUpload(file, responseId).then((response)=>{
            console.log(response.data);
            //setContentMode(contentModeValue);
            setZipUploadInProgress(false); 
            if (response.data.paths && response.data.paths.length > 1) {   
              setShowZipUploadSelection(true);
              setZipUploadPaths(response.data.paths);
            } else if (response.data.paths && response.data.paths.length === 1) {
              loadFileContents(response.data.paths[0].url, contentModeValue);
            }            
          });
        });
    }
  }

  const loadFileContents = (url, contentModeValue) => {
    const config = {
        headers: {
            'content-type': 'text/html',
        }
    }
    get(url, config).then((response)=> {
      //console.log("loaded html file "+ response.data);
      const text = response.data;
      if (contentModeValue === 'html_zip_editor') {
        setContentMode("html_zip_editor_processed");
        emailEditorRef.current.editor.loadDesign({html: text, classic: true});
      } else if (contentModeValue === 'html_zip_text'){
        console.log("show text in text editor");
        setContentMode("html_zip_text_processed");
        setHtmlText(text);        
      }
    })
  }

  const submitCustomParams = () => {
    const url = 'https://api.eiskot.com/customParams';
    const config = {
        headers: {
            'content-type': 'application/json',
        }
    }
    const customParams = base64_encode('{"Some Key":"Some Value"}');
    return  post(url, customParams,config);
  }

  const zipFileUpload = (file, id, token) => {
    
    const url = `https://api.eiskot.com/np/v1/cdnupdate?id=${id}`;
    const formData = new FormData();
    formData.append('file',file);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'X-Access-Token': token

        }
    }
    return  post(url, formData,config)
  }

  const uploadFileAndMakePublic = (e) => {
    const f = e.target.files[0];
    post('https://api.eiskot.com/uploadfile?update=NOTHING&filename='+f.name).then((response)=>{
      console.log(response.data);
      const apiResponse = response.data.path;
      const id_key = response.data.id;
      /*
      var formData = {
        ...apiResponse.fields,
        file: f
      }*/

      const formData = new FormData();
      Object.keys(apiResponse.fields).forEach(key => {
        formData.append(key, apiResponse.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", f);

      const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'x-amz-acl': 'public-read'
        }
      } 

      post(apiResponse.url, formData, config).then((response)=>{
        console.log(response.data);      
        //mark it as public
        post('https://api.eiskot.com/np/v1/updatecode?update='+id_key).then((response)=>{
          //console.log("public url details "+ response.data);
          navigator.clipboard.writeText(response.data.url);
          alert("Copied upload image public URL to Clipboard : \n"+ response.data.url);
        });  
      });
    });
  }

  const importDesignFile = async (e, contentModeValue) => {
    setContentMode(contentModeValue);
    
    if (contentModeValue === 'blank_design_editor') {
      return;
    }
    
    let newe=e;
    return getJwtToken(newe.target.files).then((value) => { 
      
      console.log(value);
      zipFileUpload(value.files[0], '1', value.token).then((response)=>{
        alert ("Uploaded successfully" + response.data);
        console.log(response);
      });
    })
   
    
  }

  return (
    <div className="design-studio-container">
      <div className="design-studio-bar">
       
          <>
            <div>
              
              <button htmlFor="choose-file" className="custom-file-upload" onClick={(e)=>{e.preventDefault();document.getElementById("choose-file").click();}}  id="choose-file-label">
                 Upload Content
              </button>
              <input type="file"  id="choose-file" style={{display:"none"}}  onChange={(e)=>importDesignFile(e,"file_design_editor")} />
            </div>
            
            
          </>
      
     
      </div>
     

     
      
          
    </div>
  );
};

export default Cdn;